/* @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap"); */
/* @import url("../public/assets/font/montserrat/MontserratBold-DOWZd.ttf"); */
@font-face {
  font-family: 'Montserrat Bold';
  src: url('../public/assets/font/montserrat/MontserratBold-DOWZd.ttf') format('truetype');
  /* Add additional font formats here if needed */
}
html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: 'Montserrat Bold', sans-serif;
  margin: 0;
  padding: 0;
  background: url(../public/assets/bg10.jpeg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}