@font-face {
  font-family: 'Party Confetti Regular';
  src: url('../public/assets/font/PartyConfetti/PartyConfettiRegular-eZOn3.ttf');
}

.party-confetti-text {
    font-family: 'Party Confetti Regular', sans-serif;
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('../public/assets/font/montserrat/MontserratBold-DOWZd.ttf');
}

@font-face {
  font-family: 'Montserrat Light';
  src: url('../public/assets/font/montserrat/MontserratLight-ywBvq.ttf');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url('../public/assets/font/montserrat/MontserratMedium-nRxlJ.ttf');
}

.montserrat-bold {
    font-family: 'Montserrat Bold', sans-serif;
}
.montserrat-light {
    font-family: 'Montserrat Light', sans-serif;
}
.montserrat-medium {
    font-family: 'Montserrat Medium', sans-serif;
}